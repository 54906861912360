@import url("http://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,400,600");
@import url("font-awesome.min.css");

/*
	Overflow by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Reset */

	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block;
	}

	body {
		line-height: 1;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		quotes: none;
	}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	body {
		-webkit-text-size-adjust: none;
	}

/* Box Model */

	*, *:before, *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

/* Containers */

	.container {
		margin-left: auto;
		margin-right: auto;
	}

	.container.\31 25\25 {
		width: 100%;
		max-width: 1425px;
		min-width: 1140px;
	}

	.container.\37 5\25 {
		width: 855px;
	}

	.container.\35 0\25 {
		width: 570px;
	}

	.container.\32 5\25 {
		width: 285px;
	}

	.container {
		width: 1140px;
	}

	@media screen and (max-width: 1680px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 1200px;
			min-width: 960px;
		}

		.container.\37 5\25 {
			width: 720px;
		}

		.container.\35 0\25 {
			width: 480px;
		}

		.container.\32 5\25 {
			width: 240px;
		}

		.container {
			width: 960px;
		}

	}

	@media screen and (max-width: 1080px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 118.75%;
			min-width: 95%;
		}

		.container.\37 5\25 {
			width: 71.25%;
		}

		.container.\35 0\25 {
			width: 47.5%;
		}

		.container.\32 5\25 {
			width: 23.75%;
		}

		.container {
			width: 95%;
		}

	}

	@media screen and (max-width: 840px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 118.75%;
			min-width: 95%;
		}

		.container.\37 5\25 {
			width: 71.25%;
		}

		.container.\35 0\25 {
			width: 47.5%;
		}

		.container.\32 5\25 {
			width: 23.75%;
		}

		.container {
			width: 95%;
		}

	}

	@media screen and (max-width: 736px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 118.75%;
			min-width: 95%;
		}

		.container.\37 5\25 {
			width: 71.25%;
		}

		.container.\35 0\25 {
			width: 47.5%;
		}

		.container.\32 5\25 {
			width: 23.75%;
		}

		.container {
			width: 95% !important;
		}

	}

/* Grid */

	.row {
		border-bottom: solid 1px transparent;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row > * {
		float: left;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row:after, .row:before {
		content: '';
		display: block;
		clear: both;
		height: 0;
	}

	.row.uniform > * > :first-child {
		margin-top: 0;
	}

	.row.uniform > * > :last-child {
		margin-bottom: 0;
	}

	.row.\30 \25 > * {
		padding: 0px 0 0 0px;
	}

	.row.\30 \25 {
		margin: 0px 0 -1px 0px;
	}

	.row.uniform.\30 \25 > * {
		padding: 0px 0 0 0px;
	}

	.row.uniform.\30 \25 {
		margin: 0px 0 -1px 0px;
	}

	.row > * {
		padding: 40px 0 0 40px;
	}

	.row {
		margin: -40px 0 -1px -40px;
	}

	.row.uniform > * {
		padding: 40px 0 0 40px;
	}

	.row.uniform {
		margin: -40px 0 -1px -40px;
	}

	.row.\32 00\25 > * {
		padding: 80px 0 0 80px;
	}

	.row.\32 00\25 {
		margin: -80px 0 -1px -80px;
	}

	.row.uniform.\32 00\25 > * {
		padding: 80px 0 0 80px;
	}

	.row.uniform.\32 00\25 {
		margin: -80px 0 -1px -80px;
	}

	.row.\31 50\25 > * {
		padding: 60px 0 0 60px;
	}

	.row.\31 50\25 {
		margin: -60px 0 -1px -60px;
	}

	.row.uniform.\31 50\25 > * {
		padding: 60px 0 0 60px;
	}

	.row.uniform.\31 50\25 {
		margin: -60px 0 -1px -60px;
	}

	.row.\35 0\25 > * {
		padding: 20px 0 0 20px;
	}

	.row.\35 0\25 {
		margin: -20px 0 -1px -20px;
	}

	.row.uniform.\35 0\25 > * {
		padding: 20px 0 0 20px;
	}

	.row.uniform.\35 0\25 {
		margin: -20px 0 -1px -20px;
	}

	.row.\32 5\25 > * {
		padding: 10px 0 0 10px;
	}

	.row.\32 5\25 {
		margin: -10px 0 -1px -10px;
	}

	.row.uniform.\32 5\25 > * {
		padding: 10px 0 0 10px;
	}

	.row.uniform.\32 5\25 {
		margin: -10px 0 -1px -10px;
	}

	.\31 2u, .\31 2u\24 {
		width: 100%;
		clear: none;
		margin-left: 0;
	}

	.\31 1u, .\31 1u\24 {
		width: 91.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\31 0u, .\31 0u\24 {
		width: 83.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\39 u, .\39 u\24 {
		width: 75%;
		clear: none;
		margin-left: 0;
	}

	.\38 u, .\38 u\24 {
		width: 66.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\37 u, .\37 u\24 {
		width: 58.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\36 u, .\36 u\24 {
		width: 50%;
		clear: none;
		margin-left: 0;
	}

	.\35 u, .\35 u\24 {
		width: 41.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\34 u, .\34 u\24 {
		width: 33.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\33 u, .\33 u\24 {
		width: 25%;
		clear: none;
		margin-left: 0;
	}

	.\32 u, .\32 u\24 {
		width: 16.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\31 u, .\31 u\24 {
		width: 8.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\31 2u\24 + *,
	.\31 1u\24 + *,
	.\31 0u\24 + *,
	.\39 u\24 + *,
	.\38 u\24 + *,
	.\37 u\24 + *,
	.\36 u\24 + *,
	.\35 u\24 + *,
	.\34 u\24 + *,
	.\33 u\24 + *,
	.\32 u\24 + *,
	.\31 u\24 + * {
		clear: left;
	}

	.\-11u {
		margin-left: 91.66667%;
	}

	.\-10u {
		margin-left: 83.33333%;
	}

	.\-9u {
		margin-left: 75%;
	}

	.\-8u {
		margin-left: 66.66667%;
	}

	.\-7u {
		margin-left: 58.33333%;
	}

	.\-6u {
		margin-left: 50%;
	}

	.\-5u {
		margin-left: 41.66667%;
	}

	.\-4u {
		margin-left: 33.33333%;
	}

	.\-3u {
		margin-left: 25%;
	}

	.\-2u {
		margin-left: 16.66667%;
	}

	.\-1u {
		margin-left: 8.33333%;
	}

	@media screen and (max-width: 1680px) {

		.row > * {
			padding: 40px 0 0 40px;
		}

		.row {
			margin: -40px 0 -1px -40px;
		}

		.row.uniform > * {
			padding: 40px 0 0 40px;
		}

		.row.uniform {
			margin: -40px 0 -1px -40px;
		}

		.row.\32 00\25 > * {
			padding: 80px 0 0 80px;
		}

		.row.\32 00\25 {
			margin: -80px 0 -1px -80px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 80px 0 0 80px;
		}

		.row.uniform.\32 00\25 {
			margin: -80px 0 -1px -80px;
		}

		.row.\31 50\25 > * {
			padding: 60px 0 0 60px;
		}

		.row.\31 50\25 {
			margin: -60px 0 -1px -60px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 60px 0 0 60px;
		}

		.row.uniform.\31 50\25 {
			margin: -60px 0 -1px -60px;
		}

		.row.\35 0\25 > * {
			padding: 20px 0 0 20px;
		}

		.row.\35 0\25 {
			margin: -20px 0 -1px -20px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 20px 0 0 20px;
		}

		.row.uniform.\35 0\25 {
			margin: -20px 0 -1px -20px;
		}

		.row.\32 5\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.\32 5\25 {
			margin: -10px 0 -1px -10px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.uniform.\32 5\25 {
			margin: -10px 0 -1px -10px;
		}

		.\31 2u\28wide\29, .\31 2u\24\28wide\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28wide\29, .\31 1u\24\28wide\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28wide\29, .\31 0u\24\28wide\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28wide\29, .\39 u\24\28wide\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28wide\29, .\38 u\24\28wide\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28wide\29, .\37 u\24\28wide\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28wide\29, .\36 u\24\28wide\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28wide\29, .\35 u\24\28wide\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28wide\29, .\34 u\24\28wide\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28wide\29, .\33 u\24\28wide\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28wide\29, .\32 u\24\28wide\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28wide\29, .\31 u\24\28wide\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28wide\29 + *,
		.\31 1u\24\28wide\29 + *,
		.\31 0u\24\28wide\29 + *,
		.\39 u\24\28wide\29 + *,
		.\38 u\24\28wide\29 + *,
		.\37 u\24\28wide\29 + *,
		.\36 u\24\28wide\29 + *,
		.\35 u\24\28wide\29 + *,
		.\34 u\24\28wide\29 + *,
		.\33 u\24\28wide\29 + *,
		.\32 u\24\28wide\29 + *,
		.\31 u\24\28wide\29 + * {
			clear: left;
		}

		.\-11u\28wide\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28wide\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28wide\29 {
			margin-left: 75%;
		}

		.\-8u\28wide\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28wide\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28wide\29 {
			margin-left: 50%;
		}

		.\-5u\28wide\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28wide\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28wide\29 {
			margin-left: 25%;
		}

		.\-2u\28wide\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28wide\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (max-width: 1080px) {

		.row > * {
			padding: 40px 0 0 40px;
		}

		.row {
			margin: -40px 0 -1px -40px;
		}

		.row.uniform > * {
			padding: 40px 0 0 40px;
		}

		.row.uniform {
			margin: -40px 0 -1px -40px;
		}

		.row.\32 00\25 > * {
			padding: 80px 0 0 80px;
		}

		.row.\32 00\25 {
			margin: -80px 0 -1px -80px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 80px 0 0 80px;
		}

		.row.uniform.\32 00\25 {
			margin: -80px 0 -1px -80px;
		}

		.row.\31 50\25 > * {
			padding: 60px 0 0 60px;
		}

		.row.\31 50\25 {
			margin: -60px 0 -1px -60px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 60px 0 0 60px;
		}

		.row.uniform.\31 50\25 {
			margin: -60px 0 -1px -60px;
		}

		.row.\35 0\25 > * {
			padding: 20px 0 0 20px;
		}

		.row.\35 0\25 {
			margin: -20px 0 -1px -20px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 20px 0 0 20px;
		}

		.row.uniform.\35 0\25 {
			margin: -20px 0 -1px -20px;
		}

		.row.\32 5\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.\32 5\25 {
			margin: -10px 0 -1px -10px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.uniform.\32 5\25 {
			margin: -10px 0 -1px -10px;
		}

		.\31 2u\28normal\29, .\31 2u\24\28normal\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28normal\29, .\31 1u\24\28normal\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28normal\29, .\31 0u\24\28normal\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28normal\29, .\39 u\24\28normal\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28normal\29, .\38 u\24\28normal\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28normal\29, .\37 u\24\28normal\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28normal\29, .\36 u\24\28normal\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28normal\29, .\35 u\24\28normal\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28normal\29, .\34 u\24\28normal\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28normal\29, .\33 u\24\28normal\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28normal\29, .\32 u\24\28normal\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28normal\29, .\31 u\24\28normal\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28normal\29 + *,
		.\31 1u\24\28normal\29 + *,
		.\31 0u\24\28normal\29 + *,
		.\39 u\24\28normal\29 + *,
		.\38 u\24\28normal\29 + *,
		.\37 u\24\28normal\29 + *,
		.\36 u\24\28normal\29 + *,
		.\35 u\24\28normal\29 + *,
		.\34 u\24\28normal\29 + *,
		.\33 u\24\28normal\29 + *,
		.\32 u\24\28normal\29 + *,
		.\31 u\24\28normal\29 + * {
			clear: left;
		}

		.\-11u\28normal\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28normal\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28normal\29 {
			margin-left: 75%;
		}

		.\-8u\28normal\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28normal\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28normal\29 {
			margin-left: 50%;
		}

		.\-5u\28normal\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28normal\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28normal\29 {
			margin-left: 25%;
		}

		.\-2u\28normal\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28normal\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (max-width: 840px) {

		.row > * {
			padding: 30px 0 0 30px;
		}

		.row {
			margin: -30px 0 -1px -30px;
		}

		.row.uniform > * {
			padding: 30px 0 0 30px;
		}

		.row.uniform {
			margin: -30px 0 -1px -30px;
		}

		.row.\32 00\25 > * {
			padding: 60px 0 0 60px;
		}

		.row.\32 00\25 {
			margin: -60px 0 -1px -60px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 60px 0 0 60px;
		}

		.row.uniform.\32 00\25 {
			margin: -60px 0 -1px -60px;
		}

		.row.\31 50\25 > * {
			padding: 45px 0 0 45px;
		}

		.row.\31 50\25 {
			margin: -45px 0 -1px -45px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 45px 0 0 45px;
		}

		.row.uniform.\31 50\25 {
			margin: -45px 0 -1px -45px;
		}

		.row.\35 0\25 > * {
			padding: 15px 0 0 15px;
		}

		.row.\35 0\25 {
			margin: -15px 0 -1px -15px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 15px 0 0 15px;
		}

		.row.uniform.\35 0\25 {
			margin: -15px 0 -1px -15px;
		}

		.row.\32 5\25 > * {
			padding: 7.5px 0 0 7.5px;
		}

		.row.\32 5\25 {
			margin: -7.5px 0 -1px -7.5px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 7.5px 0 0 7.5px;
		}

		.row.uniform.\32 5\25 {
			margin: -7.5px 0 -1px -7.5px;
		}

		.\31 2u\28narrow\29, .\31 2u\24\28narrow\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28narrow\29, .\31 1u\24\28narrow\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28narrow\29, .\31 0u\24\28narrow\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28narrow\29, .\39 u\24\28narrow\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28narrow\29, .\38 u\24\28narrow\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28narrow\29, .\37 u\24\28narrow\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28narrow\29, .\36 u\24\28narrow\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28narrow\29, .\35 u\24\28narrow\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28narrow\29, .\34 u\24\28narrow\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28narrow\29, .\33 u\24\28narrow\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28narrow\29, .\32 u\24\28narrow\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28narrow\29, .\31 u\24\28narrow\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28narrow\29 + *,
		.\31 1u\24\28narrow\29 + *,
		.\31 0u\24\28narrow\29 + *,
		.\39 u\24\28narrow\29 + *,
		.\38 u\24\28narrow\29 + *,
		.\37 u\24\28narrow\29 + *,
		.\36 u\24\28narrow\29 + *,
		.\35 u\24\28narrow\29 + *,
		.\34 u\24\28narrow\29 + *,
		.\33 u\24\28narrow\29 + *,
		.\32 u\24\28narrow\29 + *,
		.\31 u\24\28narrow\29 + * {
			clear: left;
		}

		.\-11u\28narrow\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28narrow\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28narrow\29 {
			margin-left: 75%;
		}

		.\-8u\28narrow\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28narrow\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28narrow\29 {
			margin-left: 50%;
		}

		.\-5u\28narrow\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28narrow\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28narrow\29 {
			margin-left: 25%;
		}

		.\-2u\28narrow\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28narrow\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (max-width: 736px) {

		.row > * {
			padding: 20px 0 0 20px;
		}

		.row {
			margin: -20px 0 -1px -20px;
		}

		.row.uniform > * {
			padding: 20px 0 0 20px;
		}

		.row.uniform {
			margin: -20px 0 -1px -20px;
		}

		.row.\32 00\25 > * {
			padding: 40px 0 0 40px;
		}

		.row.\32 00\25 {
			margin: -40px 0 -1px -40px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 40px 0 0 40px;
		}

		.row.uniform.\32 00\25 {
			margin: -40px 0 -1px -40px;
		}

		.row.\31 50\25 > * {
			padding: 30px 0 0 30px;
		}

		.row.\31 50\25 {
			margin: -30px 0 -1px -30px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 30px 0 0 30px;
		}

		.row.uniform.\31 50\25 {
			margin: -30px 0 -1px -30px;
		}

		.row.\35 0\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.\35 0\25 {
			margin: -10px 0 -1px -10px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.uniform.\35 0\25 {
			margin: -10px 0 -1px -10px;
		}

		.row.\32 5\25 > * {
			padding: 5px 0 0 5px;
		}

		.row.\32 5\25 {
			margin: -5px 0 -1px -5px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 5px 0 0 5px;
		}

		.row.uniform.\32 5\25 {
			margin: -5px 0 -1px -5px;
		}

		.\31 2u\28mobile\29, .\31 2u\24\28mobile\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28mobile\29, .\31 1u\24\28mobile\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28mobile\29, .\31 0u\24\28mobile\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28mobile\29, .\39 u\24\28mobile\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28mobile\29, .\38 u\24\28mobile\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28mobile\29, .\37 u\24\28mobile\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28mobile\29, .\36 u\24\28mobile\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28mobile\29, .\35 u\24\28mobile\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28mobile\29, .\34 u\24\28mobile\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28mobile\29, .\33 u\24\28mobile\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28mobile\29, .\32 u\24\28mobile\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28mobile\29, .\31 u\24\28mobile\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28mobile\29 + *,
		.\31 1u\24\28mobile\29 + *,
		.\31 0u\24\28mobile\29 + *,
		.\39 u\24\28mobile\29 + *,
		.\38 u\24\28mobile\29 + *,
		.\37 u\24\28mobile\29 + *,
		.\36 u\24\28mobile\29 + *,
		.\35 u\24\28mobile\29 + *,
		.\34 u\24\28mobile\29 + *,
		.\33 u\24\28mobile\29 + *,
		.\32 u\24\28mobile\29 + *,
		.\31 u\24\28mobile\29 + * {
			clear: left;
		}

		.\-11u\28mobile\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28mobile\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28mobile\29 {
			margin-left: 75%;
		}

		.\-8u\28mobile\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28mobile\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28mobile\29 {
			margin-left: 50%;
		}

		.\-5u\28mobile\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28mobile\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28mobile\29 {
			margin-left: 25%;
		}

		.\-2u\28mobile\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28mobile\29 {
			margin-left: 8.33333%;
		}

	}

/* Basic */

	body {
		background-color: #183026;
		background-image: url("../../images/bgwj.png");
		background-repeat: repeat;
		background-attachment: fixed, fixed;
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 18pt;
		line-height: 1.75em;
		font-weight: 300;
		letter-spacing: 1px;
		color: #3a3939;
		text-shadow: 0 0 0.5px rgba(58, 57, 57, 0.25);
		-webkit-text-stroke: 0.25px;
	}

		body.is-scroll {
			background-attachment: scroll, scroll;
			background-size: auto, 100% auto;
		}

		body.is-loading * {
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
		}

	input, textarea, select {
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 18pt;
		line-height: 1.75em;
		font-weight: 300;
		letter-spacing: 1px;
		color: #3a3939;
		text-shadow: 0 0 0.5px rgba(58, 57, 57, 0.25);
		-webkit-text-stroke: 0.25px;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 400;
		text-transform: uppercase;
		line-height: 1.75em;
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		color: inherit;
		text-decoration: none;
	}

	h2 {
		font-size: 1.25em;
		letter-spacing: 8px;
	}

	h3 {
		font-size: 1em;
		letter-spacing: 5px;
	}

	strong, b {
		font-weight: 400;
	}

	em, i {
		font-style: italic;
	}

	a {
		-moz-transition: border-color 0.35s ease-in-out, color 0.35s ease-in-out;
		-webkit-transition: border-color 0.35s ease-in-out, color 0.35s ease-in-out;
		-ms-transition: border-color 0.35s ease-in-out, color 0.35s ease-in-out;
		transition: border-color 0.35s ease-in-out, color 0.35s ease-in-out;
		color: #35b88f;
		text-shadow: 0 0 0.5px rgba(53, 184, 143, 0.25);
		text-decoration: none;
		border-bottom: dotted 1px rgba(53, 184, 143, 0.5);
	}

		a:hover {
			border-bottom-color: rgba(53, 184, 143, 0);
		}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	hr {
		border: 0;
		border-top: solid 1px #dad9d9;
		margin: 2em 0 2em 0;
	}

	blockquote {
		border-left: solid 0.5em #eae9e9;
		padding: 1em 0 1em 2em;
		font-style: italic;
	}

	p, ul, ol, dl, table {
		margin-bottom: 1em;
	}

	header {
		margin-bottom: 1em;
	}

		header p {
			display: block;
			margin: 0.5em 0 0 0;
			padding: 0 0 1.5em 0;
		}

	footer {
		margin-top: 1em;
	}

	br.clear {
		clear: both;
	}

/* Sections/Article */

	section, article {
		margin-bottom: 3em;
	}

	section > :last-child,
	article > :last-child,
	section:last-child,
	article:last-child {
		margin-bottom: 0;
	}

	.row > section, .row > article {
		margin-bottom: 0;
	}

/* Image */

	.image {
		display: inline-block;
		border: 0;
	}

		.image img {
			display: block;
			width: 100%;
		}

		.image.featured {
			display: block;
			width: 100%;
			margin: 0 0 2em 0;
		}

		.image.fit {
			display: block;
			width: 100%;
		}

		.image.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		.image.centered {
			display: block;
			margin: 0 0 2em 0;
		}

			.image.centered img {
				margin: 0 auto;
				width: auto;
			}

/* List */

	ul.default {
		list-style: disc;
		padding-left: 1em;
	}

		ul.default li {
			padding-left: 1.5em;
			margin-top: 1.5em;
		}

			ul.default li:first-child {
				margin-top: 0;
			}

	ul.icons {
		cursor: default;
	}

		ul.icons li {
			display: inline-block;
		}

		ul.icons a {
			display: inline-block;
			width: 2.5em;
			height: 2.5em;
			line-height: 2.5em;
			text-align: center;
			border: 0;
		}

	ul.menu {
		cursor: default;
		
			background: rgba(0, 0, 0, 0.4);
	}

		ul.menu li {
			display: inline-block;
			line-height: 1em;
			border-left: solid 1px #dad9d9;
			padding: 0 0 0 0.5em;
			margin: 0 0 0 0.5em;
		}

			ul.menu li:first-child {
				border-left: 0;
				padding-left: 0;
				margin-left: 0;
			}

	ul.actions {
		cursor: default;
	}

		ul.actions li {
			display: inline-block;
			margin: 0 0 0 0.5em;
		}

			ul.actions li:first-child {
				margin-left: 0;
			}

	ol.default {
		list-style: decimal;
		padding-left: 1.25em;
	}

		ol.default li {
			padding-left: 1.25em;
			margin-top: 1.5em;
		}

/* Form */

	form label {
		display: block;
		margin: 0 0 0.5em 0;
	}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"],
	form select,
	form textarea {
		-moz-transition: background-color 0.35s ease-in-out;
		-webkit-transition: background-color 0.35s ease-in-out;
		-ms-transition: background-color 0.35s ease-in-out;
		transition: background-color 0.35s ease-in-out;
		-webkit-appearance: none;
		display: block;
		border: 0;
		background: #f1f1f1;
		width: 100%;
		padding: 0.75em;
	}

		form input[type="text"]:focus,
		form input[type="email"]:focus,
		form input[type="password"]:focus,
		form select:focus,
		form textarea:focus {
			background: #f8f8f8;
		}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"],
	form select {
		line-height: 1em;
		background: rgba(255, 255, 255, 0.7);
	}

	form textarea {
		min-height: 12em;
		background: rgba(255, 255, 255, 0.7);
	}

	form .formerize-placeholder {
		color: #5a5959 !important;
	}

	form ::-webkit-input-placeholder {
		color: #5a5959 !important;
	}

	form :-moz-placeholder {
		color: #5a5959 !important;
	}

	form ::-moz-placeholder {
		color: #5a5959 !important;
	}

	form :-ms-input-placeholder {
		color: #5a5959 !important;
	}

	form ::-moz-focus-inner {
		border: 0;
	}

	form ul.actions {
		margin-bottom: 0;
		text-align: center;
	}

/* Table */

	.table-wrapper {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	table {
		width: 100%;
	}

		table.default {
			width: 100%;
		}

			table.default tbody tr {
				border-top: solid 1px #eae9e9;
			}

				table.default tbody tr:first-child {
					border-top: 0;
				}

			table.default td {
				padding: 0.75em 1.25em 0.75em 1.25em;
			}

			table.default th {
				text-align: left;
				font-weight: 400;
				padding: 0.75em 1.25em 0.75em 1.25em;
			}

			table.default thead {
				background: #f5f5f5;
			}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		-moz-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out;
		-webkit-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out;
		-ms-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out;
		transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out;
		-webkit-appearance: none;
		position: relative;
		display: inline-block;
		border: 0;
		background: rgba(0, 0, 0, 0.4);
		color: #fff;
		text-shadow: 0 0 0.5px rgba(255, 255, 255, 0.25);
		cursor: pointer;
		text-decoration: none;
		outline: 0;
		padding: 1em 3em 1em 3em;
		text-align: center;
		border-radius: 3em;
		font-weight: 400;
	}

		input[type="button"]:hover,
		input[type="submit"]:hover,
		input[type="reset"]:hover,
		button:hover,
		.button:hover {
			background: rgba(0, 0, 0, 0.6);
		}

		input[type="button"].style2,
		input[type="submit"].style2,
		input[type="reset"].style2,
		button.style2,
		.button.style2 {
			color: #fff;
			background: rgba(255, 255, 255, 0.4);
			box-shadow: inset 0 0 0 1px #fff;
		}

			input[type="button"].style2:hover,
			input[type="submit"].style2:hover,
			input[type="reset"].style2:hover,
			button.style2:hover,
			.button.style2:hover {
				background: rgba(255, 255, 255, 0.1);
				color: #fff !important;
				text-shadow: 0 0 0.5px rgba(255, 255, 255, 0.25) !important;
			}

		input[type="button"].style3,
		input[type="submit"].style3,
		input[type="reset"].style3,
		button.style3,
		.button.style3 {
			background: none;
			color: #3a3939;
			box-shadow: inset 0 0 0 1px #dad9d9;
		}

			input[type="button"].style3:hover,
			input[type="submit"].style3:hover,
			input[type="reset"].style3:hover,
			button.style3:hover,
			.button.style3:hover {
				background: rgba(58, 57, 57, 0.025);
			}

/* Box */

	.box {
		position: relative;
		margin-top: 9em;
		margin-bottom: 0;
	}

		.box:after {
			content: '';
			display: block;
			position: absolute;
			top: -9em;
			left: 50%;
			height: 9em;
			border-left: solid 1px #fff;
		}

		.box:before {
			content: '';
			display: block;
			width: 90px;
			height: 66px;
			position: absolute;
			left: 50%;
			top: -4.5em;
			margin-left: -45px;
			margin-top: -33px;
			background: url("/images/arrow.svg") no-repeat;
		}

		.box :last-child {
			margin-bottom: 0;
		}

		.box.style1 {
			background: rgba(255, 255, 255, 0.7);
			padding: 8em;
		}

			.box.style1 .image {
				display: block;
				position: absolute;
				top: 0;
				width: 50%;
				height: 100%;
				background: #152E33;
				overflow: hidden;
			}

				.box.style1 .image img {
					position: absolute;
					top: 0;
					height: 100%;
					width: auto;
				}

			.box.style1 .inner > :last-child {
				margin: 0;
			}

			.box.style1.right .image {
				left: 0;
			}

				.box.style1.right .image img {
					right: 0;
				}

			.box.style1.right .inner {
				margin-left: 50%;
				padding-left: 3em;
			}

			.box.style1.left .image {
				right: 0;
			}

				.box.style1.left .image img {
					left: 0;
				}

			.box.style1.left .inner {
				margin-right: 50%;
				padding-right: 3em;
			}

		.box.style2 {
			text-align: center;
		}

		.box.style2 header {
			background: rgba(0, 0, 0, 0.7);
			padding: 2em 3em 2em 3em;
			margin: 0;
			color: #fff;
		}

				.box.style2 header p {
					padding: 0;
				}

			.box.style2 .inner {
				position: relative;
				padding: 40px;				
				background: rgba(0,0,0,0.7);
			}

				.box.style2 .inner:after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 50%;
					height: 100%;
					border-left: solid 1px #fff;
				}

				.box.style2 .inner .row {
					position: relative;
				}

					.box.style2 .inner .row:before {
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						left: 20px;
						width: calc(100% - 40px);
						border-bottom: solid 1px #fff;
					}

				.box.style2 .inner .image {
					position: relative;
					z-index: 1;
					padding: 20px;
				}

		.box.style3 {
			background: rgba(0, 0, 0, 0.4);
			padding:3em;
			color:#fff;
		}

			.box.style3 header {
				text-align: center;
			}

/* Icons */

	.icon {
		text-decoration: none;
	}

		.icon:before {
			display: inline-block;
			font-family: FontAwesome;
			font-size: 1.25em;
			text-decoration: none;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		.icon > .label {
			display: none;
		}

/* Header */

	#header {
		position: relative;
		color: #fff;
		text-shadow: 0 0 0.5px rgba(255, 255, 255, 0.25);
		text-align: center;
		margin: 0;
		padding: 14em 0 14em 0;
		cursor: default;
	}

		#header header {
			
			padding: 0 0 4.5em 0;
		}

			#header header h1 {
				font-weight: 600;
				font-size: 2em;
				letter-spacing: 10px;
			}

			#header header p {
				border-top: solid 1px rgba(255, 255, 255, 0.5);
				color: rgba(255, 255, 255, 0.75);
				text-shadow: 0 0 0.5px rgba(255, 255, 255, 0.1875);
				font-size: 1em;
				text-transform: uppercase;
				letter-spacing: 3px;
				padding: 0;
				margin-top: 0.35em;
			}

				#header header p:before {
					content: '';
					display: block;
					border-top: solid 1px rgba(255, 255, 255, 0.5);
					margin: 4px 0 0.8em 0;
				}

		#header footer {
			position: absolute;
			bottom: 9em;
			margin: 0;
			width: 100%;
		}

			#header footer:after {
				content: '';
				display: block;
				position: absolute;
				bottom: -9em;
				left: 50%;
				height: 9em;
				border-left: solid 1px #fff;
			}

			#header footer:before {
				content: '';
				display: block;
				width: 90px;
				height: 66px;
				position: absolute;
				left: 50%;
				bottom: -4.5em;
				margin-left: -45px;
				margin-bottom: -33px;
				background: url("/images/arrow.svg") no-repeat;
			}

/* Banner */

	#banner {
		position: relative;
		color: #fff;
		text-shadow: 0 0 0.5px rgba(255, 255, 255, 0.25);
		text-align: center;
		background:rgba(0, 0, 0, 0.4);
		padding: 5em 0 5em 0;
		margin: 0;
		/*background-size: 125% auto;*/
	}

		#banner .button {
			color: #fff;
			text-shadow: 0 0 0.5px rgba(53, 184, 130, 0.25);
		}

		#banner header {
			margin: 0 0 2em 0;
		}

			#banner header h2 {
				font-weight: 400;
				font-size: 1.75em;
				letter-spacing: 8px;
			}

		#banner p {
			margin: 0;
		}

		#banner footer {
			margin: 2em 0 0 0;
		}

		#banner a {
			color: inherit;
			border-bottom-color: rgba(255, 255, 255, 0.5);
		}

			#banner a:hover {
				border-bottom-color: rgba(255, 255, 255, 0);
			}

/* Footer */

	#footer {
		position: relative;
		color: #fff;
		text-shadow: 0 0 0.5px rgba(255, 255, 255, 0.25);
		text-align: center;
		margin: 4.5em 0 0 0;
	}

		#footer:after {
			content: '';
			display: block;
			position: absolute;
			top: -4.5em;
			left: 50%;
			height: 4.5em;
			border-left: solid 1px #fff;
		}

		#footer a {
			color: #fff;
			text-shadow: 0 0 0.5px rgba(255, 255, 255, 0.25);
			border-bottom-color: rgba(255, 255, 255, 0.5);
		}

			#footer a:hover {
				color: #35b88f;
				text-shadow: 0 0 0.5px rgba(69, 200, 159, 0.25);
				border-bottom-color: rgba(53, 184, 143, 0.5);
			}

		#footer .icons {
			padding: 0.75em 2em 0.75em 2em;
			border-radius: 3em;
			border: solid 1px #fff;
			display: inline-block;
			margin: 0 0 3em 0;
			text-align: left;				
			background: rgba(0, 0, 0, 0.4);
		}

		#footer .copyright {
			margin: 0 0 9em 0;
			font-size: 0.8em;
		}

/* Poptrox */

	.poptrox-popup {
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		-ms-box-sizing: content-box;
		box-sizing: content-box;
		background: #fff;
		padding-bottom: 3em;
	}

		.poptrox-popup .loader {
			width: 48px;
			height: 48px;
			background: url("/images/loader.gif");
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -24px 0 0 -24px;
		}

		.poptrox-popup .caption {
			position: absolute;
			bottom: 0;
			left: 0;
			background: #fff;
			width: 100%;
			height: 3em;
			line-height: 3em;
			text-align: center;
			cursor: default;
			z-index: 1;
		}

		.poptrox-popup .nav-next,
		.poptrox-popup .nav-previous {
			-moz-transition: opacity 0.5s ease-in-out;
			-webkit-transition: opacity 0.5s ease-in-out;
			-ms-transition: opacity 0.5s ease-in-out;
			transition: opacity 0.5s ease-in-out;
			position: absolute;
			top: 0;
			width: 50%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
			background: rgba(0, 0, 0, 0.01);
			-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		}

			.poptrox-popup .nav-next:before,
			.poptrox-popup .nav-previous:before {
				content: '';
				position: absolute;
				width: 96px;
				height: 64px;
				background: url("/images/poptrox-nav.svg");
				top: calc(50% - 1.5em);
				margin: -32px 0 0 0;
			}

		.poptrox-popup:hover .nav-next,
		.poptrox-popup:hover .nav-previous {
			opacity: 0.5;
		}

			.poptrox-popup:hover .nav-next:hover,
			.poptrox-popup:hover .nav-previous:hover {
				opacity: 1.0;
			}

		.poptrox-popup .nav-next {
			right: 0;
		}

			.poptrox-popup .nav-next:before {
				right: 0;
			}

		.poptrox-popup .nav-previous {
			left: 0;
		}

			.poptrox-popup .nav-previous:before {
				-moz-transform: scaleX(-1);
				-webkit-transform: scaleX(-1);
				-ms-transform: scaleX(-1);
				transform: scaleX(-1);
				left: 0;
				-ms-filter: "FlipH";
				filter: FlipH;
			}

		.poptrox-popup .closer {
			-moz-transition: opacity 0.5s ease-in-out;
			-webkit-transition: opacity 0.5s ease-in-out;
			-ms-transition: opacity 0.5s ease-in-out;
			transition: opacity 0.5s ease-in-out;
			position: absolute;
			top: 0;
			right: 0;
			width: 64px;
			height: 64px;
			text-indent: -9999px;
			z-index: 2;
			opacity: 0;
			-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		}

			.poptrox-popup .closer:before {
				-moz-transition: background-color 0.5s ease-in-out;
				-webkit-transition: background-color 0.5s ease-in-out;
				-ms-transition: background-color 0.5s ease-in-out;
				transition: background-color 0.5s ease-in-out;
				content: '';
				display: block;
				position: absolute;
				right: 16px;
				top: 16px;
				width: 40px;
				height: 40px;
				border-radius: 100%;
				box-shadow: inset 0 0 0 1px #fff;
				background: rgba(255, 255, 255, 0.1) url("/images/poptrox-closer.svg") center center;
				color: #fff !important;
			}

		.poptrox-popup:hover .closer {
			opacity: 0.5;
		}

			.poptrox-popup:hover .closer:hover {
				opacity: 1.0;
			}

/* Wide */

	@media screen and (max-width: 1680px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 16pt;
			}

	}

/* Normal */

	@media screen and (max-width: 1080px) {

		/* Banner */

			#banner {
				padding: 4em 0 4em 0;
				background-size: auto, 175% auto;
			}

	}

/* Narrow */

	@media screen and (max-width: 840px) {

		/* Basic */

			body {
				background-color: #203936;
				background-image: url("../../images/bgwj.png");
				background-repeat: repeat;
				background-position: top left, top center;
				background-attachment: scroll, scroll;
				font-size: 14pt;
			}

			input, textarea, select {
				font-size: 14pt;
			}

			h1, h2, h3, h4, h5, h6 {
				line-height: 1.5em;
			}

			header p {
				margin: 0.25em 0 0 0;
				padding: 0 0 0.5em 0;
			}

		/* Box */

			.box {
				position: relative;
			}

				.box section {
					margin: 0 0 2em 0;
				}

				.box.style1 {
					padding: 2.5em 2em 2.5em 2em;
				}

					.box.style1.right .inner {
						padding-left: 2em;
					}

					.box.style1.left .inner {
						padding-right: 2em;
					}

				.box.style2 header {
					padding: 2em;
				}

				.box.style2 .inner {
					position: relative;
					padding: 30px;
				}

				.box.style3 {
					padding: 2.5em 2em 2.5em 2em;
				}

		/* Banner */

			#banner {
				padding: 3.5em;
				background-size: 200% auto;
			}

				#banner br {
					display: none;
				}

	}

/* Mobile */

	@media screen and (max-width: 736px) {

		/* Basic */

			* {
				text-shadow: none !important;
			}

			body, input, textarea, select {
				line-height: 1.5em;
				font-size: 12pt;
				letter-spacing: 0;
			}

			h2, h3, h4, h5, h6 {
				font-size: 1em;
			}

			h2 {
				font-size: 1.25em;
				letter-spacing: 4px;
			}

		/* List */

			ul.default li {
				padding-left: 0.5em;
			}

			ul.icons a {
				width: 2em;
				height: 2em;
				line-height: 2em;
			}

			ul.menu li {
				display: block;
				padding: 0;
				border: 0;
				margin: 1em 0 0 0;
			}

				ul.menu li:first-child {
					margin-top: 0;
				}

			ul.actions li {
				display: block;
				margin: 1em 0 0 0;
			}

				ul.actions li:first-child {
					margin-top: 0;
				}

			ol.default li {
				padding-left: 0.25em;
			}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				padding: 0.75em 0 0.75em 0;
				width: 100%;
				max-width: 320px;
			}

		/* Box */

			.box {
				margin-top: 4.5em;
			}

				.box:after {
					top: -4.5em;
					height: 4.5em;
				}

				.box:before {
					width: 45px;
					height: 33px;
					top: -2.25em;
					margin-left: -22.5px;
					margin-top: -16.5px;
					background-size: 45px 33px;
				}

				.box.style1 {
					padding: 0;
					text-align: center;
				}

					.box.style1 .image {
						position: relative !important;
						left: 0 !important;
						width: 100% !important;
						height: auto !important;
					}

						.box.style1 .image img {
							position: relative;
							height: auto;
							width: 100%;
						}

					.box.style1 .inner {
						margin: 0 !important;
						padding: 2em 1.25em 2em 1.25em !important;
					}

				.box.style2 .inner .row:before {
					display: none;
				}

				.box.style2 .inner .image {
					padding: 0;
					max-width: 250px;
					margin: 0 auto 20px auto;
				}

				.box.style3 {
					padding: 2em 1.25em 2em 1.25em;
				}

		/* Header */

			#header {
				padding: 6em 0 6em 0;
			}

				#header header {
					padding-left: 2em;
					padding-right: 2em;
				}

					#header header h1 {
						font-size: 1.75em;
						letter-spacing: 6px;
					}

				#header footer {
					bottom: 4.5em;
					padding: 0 2em 0 2em;
				}

					#header footer:after {
						bottom: -4.5em;
						left: 50%;
						height: 4.5em;
					}

					#header footer:before {
						width: 45px;
						height: 33px;
						bottom: -2.25em;
						margin-left: -22.5px;
						margin-bottom: -16.5px;
						background-size: 45px 33px;
					}

		/* Banner */

			#banner {
				padding: 3em 2em 3em 2em;
				background-size: auto 150%;
			}

				#banner header {
					margin: 0 0 1em 0;
				}

					#banner header h2 {
						font-size: 1.5em;
						letter-spacing: 6px;
					}

		/* Footer */

			#footer .icons {
				padding: 0.5em 1.25em 0.5em 1.25em;
			}

			#footer .copyright {
				font-size: 1em;
				margin: 0 0 4em 0;
			}

		/* Poptrox */

			.poptrox-popup .nav-next,
			.poptrox-popup .nav-previous {
				opacity: 1.0;
			}

				.poptrox-popup .nav-next:before,
				.poptrox-popup .nav-previous:before {
					display: none;
				}

			.poptrox-popup .closer {
				opacity: 0.5;
			}

	}
	
	.logoi img {
	
		padding: 15px;
	}
	.divlogo{
		background: rgba(255, 255, 255, 0.4);
		width:100%;
	}
	
	h1 {
		font-size: 1.25em;
		letter-spacing: 8px;
	}
	
	.load-item{
		display:none !important;
	}
	
	.encima{
		z-index: 1;
	}